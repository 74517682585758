import React, { useEffect, useState } from 'react';
import MainBurgerLogo from '../../base/svg/mainBurgerLogo'
import {container, imageContainer, burger, header } from "./index.module.scss";
import {gsap, Power0} from 'gsap';

const HomeHeader = () => {

    const [showIntro, setShowIntro] = useState(true);

    useEffect(()=> {
        gsap.timeline()
        .to(`.${imageContainer}`,{y: 0, opacity: 1, duration: 0.5, ease: Power0.easeOut})
        .to(`.${header}`, {opacity: 1, duration:.5, ease: Power0.easeOut}, "-0.5")
        .to(`.${imageContainer}`,{y: '2rem', opacity: 0, duration: 0.5, ease: Power0.easeOut}, "+1.3")
        .to(`.${header}`, {opacity: 0, duration:.8, ease: Power0.easeIn},"+1.3")
        .to(`.${container}`,{ opacity: 0, duration: 0.5, ease: Power0.easeIn},"+1.3")
        .eventCallback("onComplete", ()=> {
            setShowIntro(false)
        })
    }, []);

    return (
        <div>
            {showIntro!! && <div className={container}>
        <div className={imageContainer}>
        <MainBurgerLogo className={burger}/>
        </div>
        <h2 className={header} >Sudosix's Genesis Collection</h2>
        </div>}
        </div>
     
    );
}

export default HomeHeader;