import React, {useEffect, useState} from 'react';
import Header from '../components/composite/header'
import Footer from '../components/composite/footer';
import { childrenContainer} from '../styles/layout/Layout/index.module.scss'
import HomeHeader from '../components/composite/homeHeader';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import CountDown from '../components/composite/countdown';




const Layout = ({children, ...rest}:any) => {

  const {contentfulLaunch: {launch}} = useStaticQuery(graphql`
  query Launch {
    contentfulLaunch {
    launch
    }
  }
  
`)


  const [isOpen, setMenuOpen] = useState(false);


    const {location: {pathname}} = rest;

    useEffect(()=>{
      setMenuOpen(false)
    },[pathname]);

    return (
      <>
       <Helmet>
            <script id='web3' type="text/javascript" src="https://cdn.jsdelivr.net/npm/web3@^1.3.5/dist/web3.min.js" />
        </Helmet>
      {launch ? <>
          <Header isOpen={isOpen} setMenuOpen={setMenuOpen}/>
        <div className={childrenContainer}>
        {children}
        </div>
      <Footer/>
      <HomeHeader/></>
     :
      <CountDown/>
     }
     
      </>)
};


export default Layout;