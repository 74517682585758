import React from 'react';
import {header, homeLink, burgerIconContainer, burgerIcon, exit, exitContainer} from './index.module.scss'
import { Link } from "gatsby"
import BurgerMenuIcon from '../../base/svg/burgerMenuIcon';
import HeaderMenu from '../headerMenu'
import Exit from '../../base/svg/exit';


interface HeaderProps {
    isOpen: boolean,
    setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const Header = ({isOpen,setMenuOpen}:HeaderProps)=> {

    return (
        <>
        <div className={header}>
            <Link className={homeLink} to="/">SudoBurger</Link>
           { !isOpen ? <div onClick={()=>{setMenuOpen(true)}} className={burgerIconContainer}> 
            <BurgerMenuIcon className={burgerIcon}/> 
            </div> : 
             <div onClick={()=>{setMenuOpen(false)}} className={exitContainer}>
                <Exit className={exit}/>
            </div> }
        </div> 
        <HeaderMenu setMenuOpen={setMenuOpen} isOpen={isOpen}/>
         </>
    );
};



export default Header;