import React from 'react';
import CountDownClock from '../CountDownClock';

const CountDown = () => {

    return (
            <CountDownClock/>
    );
};


export default CountDown;