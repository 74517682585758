// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-burgers-tsx": () => import("./../../../src/pages/burgers.tsx" /* webpackChunkName: "component---src-pages-burgers-tsx" */),
  "component---src-pages-checkredeemed-tsx": () => import("./../../../src/pages/checkredeemed.tsx" /* webpackChunkName: "component---src-pages-checkredeemed-tsx" */),
  "component---src-pages-cookredeem-tsx": () => import("./../../../src/pages/cookredeem.tsx" /* webpackChunkName: "component---src-pages-cookredeem-tsx" */),
  "component---src-pages-countdown-tsx": () => import("./../../../src/pages/countdown.tsx" /* webpackChunkName: "component---src-pages-countdown-tsx" */),
  "component---src-pages-dumpsterredeem-tsx": () => import("./../../../src/pages/dumpsterredeem.tsx" /* webpackChunkName: "component---src-pages-dumpsterredeem-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-frycooks-tsx": () => import("./../../../src/pages/frycooks.tsx" /* webpackChunkName: "component---src-pages-frycooks-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ingredients-tsx": () => import("./../../../src/pages/ingredients.tsx" /* webpackChunkName: "component---src-pages-ingredients-tsx" */),
  "component---src-pages-wallet-tsx": () => import("./../../../src/pages/wallet.tsx" /* webpackChunkName: "component---src-pages-wallet-tsx" */)
}

