import React from 'react';
import { headerMenu, menuContainer, linksContainer, link} from './index.module.scss';
import { Link } from "gatsby"
import { Transition } from 'react-transition-group';



interface HeaderProps {
    isOpen: boolean
    setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const HeaderMenu = ({isOpen, setMenuOpen}:HeaderProps)=> {


    const defaultStyle:any = {
        transition: `opacity ${300}ms ease-in-out`,
        opacity: 0,
      }

    const transitionStyles:any = {
        entering: { opacity: 1 },
        entered:  { opacity: 1 },
        exiting:  { opacity: 0 },
        exited:  { opacity: 0 },
      };

    return (
        <div>
        <Transition
        in={isOpen}
        unmountOnExit={true}
        timeout={300}>
            {(state:any) => (
                    <div onClick={()=>{setMenuOpen(false)}} className={headerMenu}>
                    <div style={{
                        ...defaultStyle,
                        ...transitionStyles[state]
      }} className={linksContainer}>
                        <div className={menuContainer}>
                            <Link className={link} to="/frycooks">FryCooks</Link>
                            <Link  className={link} to="/ingredients">Ingredients</Link>
                            <Link  className={link} to="/burgers">SudoBurgers</Link>
                            <Link  className={link} to="/wallet">Wallet</Link>
                            <Link  className={link} to="/about">About</Link>
                            <Link  className={link} to="/faq">FAQ</Link>
                      </div>
                    </div>
                    </div>
            )}
 
        </Transition>
        </div>
    );
};

export default HeaderMenu;