import React, { SVGAttributes } from 'react';


const OpenSea = ({className}: SVGAttributes<SVGElement>)=> {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 285.83 257.28"><g><g><path className={className} d="M93.23,176.59c1.19-2.11,2.25-4.12,3.43-6.07,3.56-5.85,7.1-11.71,10.76-17.49,14.56-23,17.26-47.81,9-73.33-5.8-17.84-13.54-35-20.45-52.52-.7-1.78-1.68-3.46-2.41-5.23-.21-.52,0-1.21.13-2.91l34.53,8.69c0-5.2-.16-9.2,0-13.18C128.64,5.48,134.08-.19,141.94,0c7.5.18,13.58,6.1,12.67,14.69-1.46,13.78,2.8,23.09,14.44,31.49C184.16,57.08,196,71.71,205.52,88c11.42,19.65,11.87,39.08-1.48,57.87-6.3,8.86-13.66,17-20.47,25.47-5,6.25-11.37,8-19.25,7.55-9.41-.6-9.44-.12-9.46,9.31-.05,15.92,0,15.2,15.91,16.14,13.88.82,24.7-3.57,33.28-14.15,4.78-5.89,10.93-9,18.25-10.95,18.6-5,37-10.63,55.51-15.94,7.06-2,7.24-1.49,7.89,6,.68,7.7-1.34,12.62-9.16,15.77-11.21,4.51-19.27,13-25.63,23.37a373.85,373.85,0,0,1-21.62,31.82c-8.8,11.54-20.68,17.14-35.62,17-38.52-.34-77,.05-115.58-.16-41.8-.23-75.25-31.63-77.92-72.75-.55-8.43-.21-8.89,8.07-8.92,18.19-.05,36.39.11,54.58-.09,5.66-.06,8.7.86,9.24,7.79C73,195.42,82.6,203.65,95,204.06c9,.3,18-.06,27,.14,4.32.09,6.27-1.43,6.2-5.92-.37-24.11,3.11-18.85-18.21-19.51-4.7-.14-9.4-.2-14.1-.44C95.11,178.29,94.46,177.44,93.23,176.59Z"/><path d="M79.15,48.45c2.21,2.83,3.36,3.83,3.9,5.09,6.88,16,12.49,32.25,14.45,49.7,1.91,17-5.88,30.71-14.15,44.29-.88,1.44-3.57,2.48-5.43,2.5-18.61.19-37.23.13-55.84.13-5,0-6.17-2-3.41-6.31Q47.55,98.72,76.35,53.52C77.25,52.1,78,50.57,79.15,48.45Z"/></g></g></svg>
    );
};


export default OpenSea;