import React from "react"
import MainBurgerLogo from "../../base/svg/mainBurgerLogo"
import {
  countdownContainer,
  countdownWidet,
  burgerContainer,
  countdownClock,
  emailLink,
  burger,
} from "./index.module.scss"

const CountDownClock = () => {
  return (
    <>
      <div className={countdownContainer}>
        <div className={countdownWidet}>
          <div className={burgerContainer}>
            <MainBurgerLogo className={burger} />
          </div>
          <h1 className={countdownClock}>Coming Soon</h1>
          <a target="_blank" className={emailLink} href="https://docs.google.com/forms/d/e/1FAIpQLSewoi53My06BwOrCciZos3ga7MYE3Ra1TpACZq3wQfoKqyzWg/viewform">
          Sign up for our mailing list!
          </a>
        </div>
      </div>
    </>
  )
}

export default CountDownClock
