import React from "react"
import Twitter from "../../base/svg/twitter"
import Discord from "../../base/svg/discord"
import Etherscan from "../../base/svg/etherscan"
import OpenSea from "../../base/svg/opensea"
import { footerContainer, iconContainer, icon,grotto,footerListContent } from "./index.module.scss"
import Grotto from "../../base/svg/grotto.svg"

const LinksContainer = ({ children, to }: any) => (
  <a target="_blank" href={to} className={iconContainer}>
    {children}
  </a>
)

const Footer = () => {
  return (
    <div className={footerContainer} >
      <div className={footerListContent}>
        <LinksContainer to="https://twitter.com/sudoburger">
          <Twitter className={icon} />
        </LinksContainer>
        <LinksContainer to="https://discord.gg/qnryEPxKwu">
          <Discord className={icon} />
        </LinksContainer>
        <LinksContainer to="https://etherscan.io/token/0xeF2e3Cf741d34732227De1dAe38cdD86939fE073">
          <Etherscan className={icon} />
        </LinksContainer>
        <LinksContainer to="https://opensea.io/collection/frycooks">
          <OpenSea className={icon} />
        </LinksContainer>
        <LinksContainer to="https://twitter.com/GROTTO_LABS">
            <Grotto className={icon}/>
        </LinksContainer>
      </div>
    </div>
  )
}

export default Footer
